<div class="dialog_wrapper">
  <div class="dialog_header">
    <div class="dialog_title">
      <h3>Thông báo</h3>
    </div>
  </div>
  <div class="dialog_content custom_expiry_content">
    <div style="margin:1rem">
      Phiên đăng nhập của bạn hết hạn
    </div>
  </div>
  <div class="dialog_footer center custom_expiry_footer">
    <div>
      <button mat-button class="error"
              (click)="onClose()">
        <i class="fas fa-cancel"></i>{{'common.Cancel' | translate}}
      </button>
    </div>
  </div>
</div>




