import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { StoreModel } from '../models/storeModel';
import { HttpParams } from '@angular/common/http';
import { ApiService, Page, SelectModel } from '@next-solutions/next-solutions-base';
import { EnumUtil } from '../utils/enum.util';
import { SuperStatusEnum } from '../enums/super.status.enum';
import { environment } from '../../environments/environment';
import { AppParamModel } from '../models/appParamModel';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from '../components/change-password/change-password.component';
import { SessionExpiryDialogComponent } from '../components/session-expiry-dialog/session-expiry-dialog.component';
import {BehaviorSubject, debounceTime} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AutoLogoutService implements OnDestroy {
  private timeout: any;
  private TIME_LIMIT?: number;
  private isInitialized = false;
  private isDialogOpen = false;

  private autoLogoutBehaviorSubject = new BehaviorSubject<any>({});

  eventsToTrack = [
    'click', 'dblclick', 'mousedown', 'mouseup', 'mousemove', 'mouseover', 'mouseout',
    'keydown', 'keyup', 'keypress', 'input', 'change', 'focus', 'blur', 'scroll',
    'resize', 'contextmenu', 'touchstart', 'touchmove', 'touchend'
  ];

  constructor(private router: Router, private apiService: ApiService, private matDialog: MatDialog) {
  }

  public startAutoLogout() {
    if (this.isInitialized) {
      console.warn('run AutoLogoutService.');
      return;
    }
    const params = new HttpParams()
      .set('status', 'ACCEPTED')
      .set('pageNumber', '1')
      .set('pageSize', environment.INTEGER_MAX_VALUE);
    Promise.all([
      this.apiService.get<Page>('/app-param', params.append('code', 'MAX_IDLE_TIME')).toPromise(),
    ]).then(async ([pageAppParam]) => {
      if (pageAppParam && pageAppParam.content?.length >= 0) {
        pageAppParam.content.forEach((v: AppParamModel) => {
          if (v.code === 'MAX_IDLE_TIME') {
            this.isInitialized = true;
            this.TIME_LIMIT = Number(v.value) * 1000;;
            this.initListener();
            this.autoLogoutBehaviorSubject.pipe(debounceTime(this.TIME_LIMIT)).subscribe(v=> {
              if (this.isInitialized && !this.isDialogOpen)
                this.logout();
            })
          }
        });
      }
    });
  }

  ngOnInit() {

  }

  private initListener() {
    this.eventsToTrack.forEach(event => {
      document.addEventListener(event, (e) => {
        this.resetTimeout();
      });
    });
  }

  private resetTimeout() {
    this.autoLogoutBehaviorSubject.next({});
  }

  private logout() {
    this.isDialogOpen = true;
    const dialogRef = this.matDialog.open(SessionExpiryDialogComponent, {
      width: '50%',
      disableClose: true,
    });
    window.sessionStorage.removeItem('token');
    dialogRef.afterClosed().subscribe(() => {
      clearTimeout(this.timeout);
      this.isInitialized = false;
      this.isDialogOpen = false;
      setTimeout(() => this.router.navigate(['/logout']).then(), 500);
    });
  }

  ngOnDestroy() {
    this.autoLogoutBehaviorSubject.unsubscribe();
  }
}
