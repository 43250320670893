import { Component, Injectable, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import {
  ApiService,
  AuthoritiesService,
  DateUtilService,
  FormStateService,
  Page,
} from '@next-solutions/next-solutions-base';
import { AuthenticationService } from '../../_services/authentication.service';
import { NavService } from '../../_services/nav.service';
import { UserUtilService } from '../../utils/user-util.service';
import { BirthdayUserService } from '../../_services/birthday-user.service';
import { UserBirthDayComponent } from '../user-birthday/user-birthday.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
@Injectable()
export class LoginComponent implements OnInit {
  form: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    remember: new FormControl(''),
  });
  error: string | null | undefined;

  constructor(public dialog: MatDialog, private formBuilder: FormBuilder, private router: Router,
              private authenticationService: AuthenticationService, private apiService: ApiService,
              private cookieService: CookieService, private location: Location, private translateService: TranslateService,
              private authoritiesService: AuthoritiesService, protected formStateService: FormStateService,
              private navService: NavService, private userUtilService: UserUtilService,
              protected dateUtilService: DateUtilService, protected birthdayUserService: BirthdayUserService,
              private matDialog: MatDialog) {
  }

  submit() {
    this.resetInfo();
    if (this.form.valid) {
      const body = new HttpParams()
        .set('username', this.form.controls.username.value)
        .set('password', this.form.controls.password.value)
        .set('grant_type', 'password');

      this.authenticationService.login(body.toString()).subscribe(data => {
        console.log('dataaaaaaaaaaa', data);
        window.sessionStorage.setItem('token', JSON.stringify(data));
        this.cookieService.set('remember', this.form.controls.remember.value.toString());
        this.hideLoginModal();
        this.apiService.patch('/user/time-last-login', null).subscribe(async (data: any) => {
          if (data) {
            const stringDateData = this.userUtilService.convertDateToDisplayServerTime(data);
            const stringDateNow = this.userUtilService.convertDateToStringCurrentGMT(new Date());
            const dateData = new Date(stringDateData ?? '');
            const dateNow = new Date(stringDateNow ?? '');
            if (dateData < dateNow) {
              if (dateNow.getDate() === 1) {
                await this.showUserBirthDayMonthFirstDay();
                await this.showUserBirthDayFirstDay();
              } else {
                await this.showUserBirthDayFirstDay();
                await this.showUserBirthDayMonthFirstDay();
              }
            } else {
              await this.getDataUserBirthDayFirstDay();
              await this.showUserBirthDayMonthFirstDay();
            }
          } else {
          }
        }, (error: any) => {
          console.log(error);
        });
        // console.log(this.location.isCurrentPathEqualTo('/logout'));
        // if (this.location.isCurrentPathEqualTo('/logout')) {
        this.router.navigate(['/home']).then();
        // this.location.back();
        // }
      }, (error) => {
        if(error.error.error === 'Unauthorized'){
          this.translateService.get('common.login.locked').subscribe(e => {
            this.error = e;
          });
        }else{
          this.translateService.get('common.login.error').subscribe(e => {
            this.error = e;
          });
        }
      });
    } else {
      this.translateService.get('common.login.error').subscribe(e => {
        this.error = e;
      });
    }
  }

  resetInfo() {
    this.authoritiesService.me = null;
    this.navService.navItems = null;
    this.formStateService.setMapState(new Map<string, FormGroup>());
  }

  ngOnInit() {
    window.sessionStorage.removeItem('token');
  }

  showLoginModal() {
    return this.dialog.open(LoginComponent, { disableClose: true });
  }

  hideLoginModal() {
    this.dialog.closeAll();
  }

  async showUserBirthDayFirstDay() {
    const params = new HttpParams()
      .set('status', 'ACCEPTED')
      .set('pageNumber', '1')
      .set('nowDate', this.dateUtilService.convertDateToStringCurrentGMT(new Date()) ?? '')
      .set('pageSize', environment.INTEGER_MAX_VALUE);
    const pageUsers = await this.apiService.get<Page>('/user', params).toPromise();
    if (pageUsers && pageUsers.content) {
      if (pageUsers.content.length > 0) {
        this.birthdayUserService.add(pageUsers.content);
        this.birthdayUserService.setTitle(false);
        this.matDialog.open(UserBirthDayComponent, {
          width: '80%',
          height: '80%',
        });
      }
    }
  }

  async showUserBirthDayMonthFirstDay() {
    const params = new HttpParams()
      .set('status', 'ACCEPTED')
      .set('pageNumber', '1')
      .set('birthdayUserInMonth', this.dateUtilService.convertDateToStringCurrentGMT(new Date()) ?? '')
      .set('pageSize', environment.INTEGER_MAX_VALUE);
    const pageUsers = await this.apiService.get<Page>('/user', params).toPromise();
    if (pageUsers && pageUsers.content) {
      this.birthdayUserService.addUserMonth(pageUsers.content);
      this.birthdayUserService.setTitle(true);
    }
  }

  async getDataUserBirthDayFirstDay() {
    const params = new HttpParams()
      .set('status', 'ACCEPTED')
      .set('pageNumber', '1')
      .set('nowDate', this.dateUtilService.convertDateToStringCurrentGMT(new Date()) ?? '')
      .set('pageSize', environment.INTEGER_MAX_VALUE);
    const pageUsers = await this.apiService.get<Page>('/user', params).toPromise();
    if (pageUsers && pageUsers.content) {
      this.birthdayUserService.setTitle(false);
      this.birthdayUserService.add(pageUsers.content);
    }
  }

}
