import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-session-expiry-dialog',
  templateUrl: './session-expiry-dialog.component.html',
  styleUrls: ['./session-expiry-dialog.component.scss'],
})
export class SessionExpiryDialogComponent {
  constructor(public dialogRef: MatDialogRef<SessionExpiryDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: number) {
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
