<div class="dialog_wrapper">
  <div class="dialog_header">
    <div class="dialog_title">
      <h3>{{'common.changePassword' | translate}}</h3>
    </div>
  </div>
  <div class="dialog_content">
    <div class="change-password">
      <form class="form" [formGroup]="addEditForm"
            fxLayout="row wrap" class="row-wrap-padding">
        <ns-input name="oldPassword"
                  formControlName="oldPassword"
                  [required]="true"
                  [type]="'password'"
                  [percentOfLabelOutside]="30"
                  [label]="'common.password.current'"
                  [errorMessages]="inputErrorMsg"
                  [isLabelOutside]="true"
                  fxFlex="100%">
        </ns-input>
        <ns-input name="newPassword"
                  formControlName="newPassword"
                  [required]="true"
                  [type]="'password'"
                  [percentOfLabelOutside]="30"
                  [label]="'common.password.new'"
                  [errorMessages]="inputErrorMsg"
                  [isLabelOutside]="true"
                  fxFlex="100%">
        </ns-input>
        <ns-input name="newConfirmPassword"
                  formControlName="newConfirmPassword"
                  [required]="true"
                  [type]="'password'"
                  [label]="'common.password.confirm.new'"
                  [errorMessages]="inputErrorMsg"
                  [isLabelOutside]="true"
                  [percentOfLabelOutside]="30"
                  fxFlex="100%">
        </ns-input>
      </form>
    </div>
  </div>
  <div class="dialog_footer center">
    <div>
      <button mat-button class="primary"
              [disabled]="addEditForm.invalid"
              (click)="onSave()">
        <i class="fas fa-save"></i>{{'btnAddEdit' | translate}}
      </button>
      <button mat-button class="error"
              *ngIf="!data?.mustChangePassWord"
              (click)="onCancel()">
        <i class="fas fa-cancel"></i>{{'common.Cancel' | translate}}
      </button>
    </div>
  </div>
</div>
