import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-password-expiry-dialog',
  templateUrl: './password-expiry-dialog.component.html',
  styleUrls: ['./password-expiry-dialog.component.scss'],
})
export class PasswordExpiryDialogComponent {
  constructor(public dialogRef: MatDialogRef<PasswordExpiryDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: number) {
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
