import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { A11yModule } from '@angular/cdk/a11y';
import { BidiModule } from '@angular/cdk/bidi';
import { ObserversModule } from '@angular/cdk/observers';
import { OverlayModule } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { PortalModule } from '@angular/cdk/portal';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { MenuListItemComponent } from './_helpers/menu-list-item/menu-list-item.component';
import { TopNavComponent } from './_helpers/top-nav/top-nav.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LogoutComponent } from './components/logout/logout.component';
import { AppRoutingModule } from './routing/app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { JsogService } from 'jsog-typescript';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { JsogHttpInterceptor, LoaderInterceptor, NextSolutionsModules } from '@next-solutions/next-solutions-base';
import { factories, service as PBIService } from 'powerbi-client';
import { SharedModule } from './modules/shared.module';
import { environment } from '../environments/environment';
import { TranslateLoaderFactoryHelper } from './_helpers/TranslateLoaderFactoryHelper';
import { GlobalErrorHandler } from './_services/errorHandle/global-error-handler';
import { AuthenticationService } from './_services/authentication.service';
import { NavService } from './_services/nav.service';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { BirthdayUserService } from './_services/birthday-user.service';
import { UserBirthDayComponent } from './components/user-birthday/user-birthday.component';
import { DialogConfirmService } from './_services/dialog-confirm.service';
import { AutoLogoutService } from './_services/autologout.service';
import { PasswordExpiryDialogComponent } from './components/change-password-expiry/password-expiry-dialog.component';
import { SessionExpiryDialogComponent } from './components/session-expiry-dialog/session-expiry-dialog.component';

export function powerBiServiceFactory() {
  return new PBIService.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory);
}

@NgModule({
  exports: [
    // CDK
    A11yModule,
    BidiModule,
    ObserversModule,
    OverlayModule,
    PlatformModule,
    PortalModule,
    CdkStepperModule,
    CdkTableModule,
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    MenuListItemComponent,
    TopNavComponent,
    DashboardComponent,
    ChangePasswordComponent,
    UserBirthDayComponent,
    PasswordExpiryDialogComponent,
    SessionExpiryDialogComponent
  ],
  imports: [
    /*ONLY ONCE TIME*/
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NextSolutionsModules.forRoot({
      data: {
        BASE_URL: environment.BASE_URL,
        BASE_AUTHORIZATION_URL: environment.BASE_AUTHORIZATION_URL,
        PAGE_SIZE: environment.PAGE_SIZE,
        PAGE_SIZE_OPTIONS: environment.PAGE_SIZE_OPTIONS,
        API_DATE_FORMAT: environment.API_DATE_FORMAT,
        DIS_DATE_FORMAT: environment.DIS_DATE_FORMAT,
        DIALOG_LOGO: '' /*environment.DIALOG_LOGO*/,
      },
    }),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: environment.DEFAULT_LANGUAGE,
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLoaderFactoryHelper.forModule(),
        deps: [HttpClient],
      },
      isolate: false,
    }),
    SharedModule,
    AppRoutingModule,

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JsogHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: 'PowerBIService', useFactory: powerBiServiceFactory },
    JsogService,
    DatePipe,
    CookieService,
    LoginComponent,
    AuthenticationService,
    NavService,
    BirthdayUserService,
    DialogConfirmService,
    AutoLogoutService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class AppModule {
}

