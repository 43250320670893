import {
  AbstractControl,
  AsyncValidatorFn,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable, of } from 'rxjs';

export class ChangePasswordService implements Validators {
  static asyncNewPasswordValidationConfirm(compareControlName: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {

      const compareControl = (control.parent! as FormGroup)?.controls[compareControlName];
      if (compareControl && compareControl.invalid) {
        compareControl.updateValueAndValidity();
      }

      let error = null;
      if (compareControl && compareControl.value === control.value) {
        error = null;
      } else if (compareControl && compareControl.value && control.value) {
        error = { checkConfirmFalse: { checkConfirmFalse: true } };
      }
      return of(error);
    };
  }

  static newPasswordValidationConfirm(compareControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      const compareControl = (control.parent! as FormGroup)?.controls[compareControlName];
      if (compareControl && compareControl.invalid) {
        compareControl.updateValueAndValidity();
      }

      let error = null;
      if (compareControl && compareControl.value === control.value) {
        error = null;
      } else if (compareControl && compareControl.value && control.value) {
        error = { checkConfirmFalse: { checkConfirmFalse: true } };
      }
      return error;
    };
  }

  static newPasswordSameOldPasswordConfirm(compareControlName: string = 'oldPassword'): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const compareControl = (control.parent! as FormGroup)?.controls[compareControlName];
      if (compareControl && compareControl.invalid) {
        compareControl.updateValueAndValidity();
      }

      let error;
      if (compareControl && compareControl.value !== control.value) {
        error = null;
      } else {
        error = { sameOldPassword: { sameOldPassword: true } };
      }
      return error;
    };
  }

  static hasAtLeastOneDigit(password: string): boolean {
    for (const char of password) {
      if (!isNaN(Number(char)) && char.trim() !== '') {
        return true;
      }
    }
    return false;
  }

  static hasMinimumCharacterRequirements(password: string): boolean {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    return hasUpperCase && hasLowerCase;
  }

  static hasMinimumLength(password: string | null, minLength: number): boolean {
    return password !== null && password.length >= minLength;
  }

  static hasMinimumSpecialCharacters(password: string, minSpecialChars: number): boolean {
    const specialCharCount = Array.from(password).filter(
      (char) => !/[a-zA-Z0-9]/.test(char),
    ).length;
    return specialCharCount >= minSpecialChars;
  }

  static validateHasAtLeastOneDigit(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let error;
      if (control.value && this.hasAtLeastOneDigit(control.value)) {
        error = null;
      } else {
        error = { leastOneDigit: { leastOneDigit: true } };
      }
      return error;
    };
  }

  static validateHasMinimumCharacterRequirements(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let error;
      if (control.value && this.hasMinimumCharacterRequirements(control.value)) {
        error = null;
      } else {
        error = { leastCharCase: { leastCharCase: true } };
      }
      return error;
    };
  }

  static validateHasMinimumLength(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let error;
      if (control.value && this.hasMinimumLength(control.value, 10)) {
        error = null;
      } else {
        error = { minLength: { minLength: true } };
      }
      return error;
    };
  }

  static validateHasMinimumSpecialCharacters(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let error;
      if (control.value && this.hasMinimumSpecialCharacters(control.value, 1)) {
        error = null;
      } else {
        error = { minSpecialChar: { minSpecialChar: true } };
      }
      return error;
    };
  }


}
